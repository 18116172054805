.safari-form-label-focused {
  left: 12px;
  font-size: 14px;
}
.chrome-form-label-focused {
  left: 4px;
}
.safari-login-focused {
  left: 12px;
  font-size: 15px;
}

.formLabel {
  position: relative;
  display: inline-block;
}

.labelSpan {
  pointer-events: none;
  position: absolute;
  left: 24px;
  top: 28%;
  transition: 0.4s;
  opacity: 0.6;
}

.important-top {
  top: 22%;
  opacity: 1;
}

.after-error-validation {
  top: 22%;
  opacity: 0.6;
}

.messageLabel {
  pointer-events: none;
  position: absolute;
  left: 24px;
  top: 10%;
  transition: 0.4s;
  opacity: 0.6;
}

.formInput:focus,
.formInput:not(:placeholder-shown) {
  outline: none;
  border: 1px solid #d1d5db;
}

.formInput:focus {
  outline: none;
  border: 2px solid #d1d5db;
}

.formInput:focus + .labelSpan,
.formInput:not(:placeholder-shown) + .labelSpan {
  opacity: 1;
  background: linear-gradient(#ffffff, #f7fbff);
  padding: 0 5px;
  top: 0%;
  color: #6b7280;
  transform: scale(0.75) translateY(-65%) translateX(-20px);
}

.formInput:focus + .messageLabel,
.formInput:not(:placeholder-shown) + .messageLabel {
  opacity: 1;
  background: linear-gradient(#ffffff, #f7fbff);
  padding: 0 5px;
  top: 0%;
  color: #6b7280;
  transform: scale(0.75) translateY(-65%) translateX(-20px);
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f7fbff inset;
}

@media screen and (max-width: 639px) {
  .labelSpan {
    left: 24px;
  }
}
