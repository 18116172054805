@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: inter, sans-serif;
}

@layer utilities {
  .masonry {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 1.5rem;
  }

  .masonry-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .masonry-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .masonry-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .break-inside {
    break-inside: avoid;
  }
}
