li::marker {
  font-size: 24px;
}

@media screen and (max-width: 630px) {
  .mobile-responsive {
    margin: 0 12px;
  }

  .list-nested-content {
    margin-left: -10px;
  }
}
